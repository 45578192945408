
.ud-meetingContacts {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .search-field-container {
        padding: .3em .3em 0;
    }

    .fiche-separator {
        display: none;
    }

    .detail-data-list {
        overflow-y: auto;
    }
}