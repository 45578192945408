@import "../../../data/config-scss/variables.scss";

.withoutIcon {
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background-color: $color2;
    padding: 1.5rem 1rem;
    margin: 10%;
    text-align: center;
}

a.custom-colored-link  {
    color: white !important;
}